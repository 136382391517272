<template>
  <div class="page">
    <aside class="first-half">
      <div>
        <figure class="logo">
          <img src="@/assets/img/logo.svg" alt="Logo"/>
        </figure>
        <form class="login-form">
          <h1 class="title">Login to your account</h1>

          <template v-if="showError">
            <p class="error-msg">
              {{ errorMsg }}
            </p>
          </template>

          <InputText
              label="Email"
              placeholder="Your email"
              type="email"
              class="mb-6"
              name="email"
              v-model="userCredentials.email"
          />
          <InputText
              label="Password"
              placeholder="Your password"
              type="password"
              name="password"
              v-model="userCredentials.password"
          />
          <Checkbox label="Stay logged in"/>

          <Button
              type="submit"
              text="Login"
              @click.native.prevent="submitLogin"
              class="w-full"
              :loading="isLoadingData"
              kind="primary"
          />
          <router-link to="/password-reset" class="forgot-password">Forgot your password?
          </router-link>
        </form>
        <footer>
          <div class="footer-container">
            <span class="credits">
              Made with ♥ remotely from Ghana, Nigeria, Mauritius & Switzerland.
            </span>
            <span class="credits copyrights">
              © {{ new Date().getFullYear() }} Travel Afrique, LLC. All rights
              reserved.
            </span>
          </div>
        </footer>
      </div>
    </aside>
    <aside class="second-half">
      <img
          class="bg-img"
          :src="require(`@/assets/img/backgrounds/${imgPath}`)"
          alt="Background Image"
      />
    </aside>
  </div>
</template>
<script>
import Button from "@/components/Buttons/Button";
import Checkbox from "@/components/Inputs/Checkbox";
import InputText from "@/components/Inputs/InputText";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Login",
  components: {
    Button,
    Checkbox,
    InputText,
  },
  data() {
    return {
      imgPath: "1.jpeg",
      showError: false,
      errorMsg: "",
      userCredentials: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    async submitLogin() {
      if (!this.userCredentials.email || !this.userCredentials.password) return;
      this.login(this.userCredentials)
          .then((response) => {
            if (response.data.user.role === "partner" && this.isAuthenticated) {
              this.$router.push(this.$route.query.redirect || {name: "Requests"});
            } else {
              throw {response: {data: {message: "Invalid credentials"}}}
            }
          })
          .catch((e) => {
            this.showError = true;
            if (e.data) {
              this.errorMsg = e.data.message;
            }
            this.userCredentials.password = "";
            setTimeout(() => {
              this.showError = false;
            }, 10000);
          });
    },

    getRandomImg(totalImg) {
      let random = Math.floor(Math.random() * totalImg);
      this.imgPath = random + ".jpeg";
    },
  },

  computed: {
    ...mapGetters({
      token: "auth/token",
      isAuthenticated: "auth/authenticated",
      isLoadingData: "auth/isLoadingData"
    }),
  },
  beforeMount() {
    this.getRandomImg(45);
  },
};
</script>
<style scoped>
.page {
  @apply w-100vw h-100vh flex sm:flex-wrap bg-grey-lighter;
}

.first-half {
  @apply sm:h-full w-full md:w-5/8 px-4 py-8 sm:p-8;
  @apply flex justify-center items-center;
}

.second-half {
  @apply hidden md:block h-full w-3/8 p-8 ml-auto relative;
}

.bg-img {
  @apply h-full w-full object-cover absolute top-0 left-0;
}

.logo {
  @apply h-13 relative my-9;
}

.logo img {
  @apply absolute object-cover sm:left-0 top-0 h-full left-1/2 -translate-x-1/2 transform sm:transform-none sm:translate-x-0;
  @apply relative left-1/2 transform -translate-x-1/2;
}

.login-form {
  @apply relative left-1/2 transform -translate-x-1/2 bg-white;
  @apply w-full max-w-sm sm:max-w-lg px-8 py-12 sm:px-12 sm:py-14;
  @apply shadow-sm border border-grey-base rounded-lg mb-4;
}

.title {
  @apply font-sans text-black-base font-semibold text-xl sm:text-2xl mb-5;
}

.error-msg {
  @apply font-sans text-red-base text-sm mb-5;
}

footer {
  @apply relative left-1/2 transform -translate-x-1/2;
  @apply max-w-sm sm:max-w-lg pb-12;
}

.footer-container {
  @apply flex justify-between flex-wrap;
}

.credits {
  @apply text-black-lighter text-xs w-full md:text-sm mb-2;
}

.copyrights {
  @apply text-left;
}

.forgot-password {
  @apply text-teal-dark underline text-sm text-center;
  @apply mt-6 block;
}
</style>